import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: 'login',
            component: () => import('../views/user'),
            props: { isLogin: true }
        },
        {
            path: "/pages/public/register",
            name: 'reg',
            component: () => import('../views/user'),
            props: { isLogin: false }
        },
        {
            path: "/pages/home/home",
            name: 'home/home',
            component: () => import('../views/home'),
            props: { tabIndex: 0 }
        },
        {
            path: "/pages/home/grab",
            name:"home/grab",
            component: () => import('../views/home'),
            props: { tabIndex: 1 }
        },
        {
            path: "/pages/home/records",
            name:"home/records",
            component: () => import('../views/home'),
            props: { tabIndex: 2 }
        },
        {
            path: "/pages/home/profile",
            name:"home/profile",
            component: () => import('../views/home'),
            props: { tabIndex: 3 }
        },
        {
            path: "/pages/public/kefu",
            name:"kefu",
            component: () => import('../views/user/Kefu'),
        },
        {
            path: "/pages/profile/login-password",
            name:"change/login-password",
            component: () => import('../views/user/ChangePassword'),
            props: { type: 1 }
        },
        {
            path: "/pages/profile/withdraw-password",
            name:"change/withdraw-password",
            component: () => import('../views/user/ChangePassword'),
            props: { type: 2 }
        },
        {
            path: "/pages/wallet/finance",
            name:"wallet/finance",
            component: () => import('../views/wallet/WalletFinance'),
        },
        {
            path: "/pages/home/message",
            name:"home/message",
            component: () => import('../views/message/NotifyRecord'),
        },
        {
            path: "/pages/wallet/bind",
            name:"wallet/bind",
            component: () => import('../views/wallet/WalletBind'),
        },
        {
            path: "/pages/wallet/withdraw",
            name:"wallet/withdraw",
            component: () => import('../views/wallet/WalletWithdraw'),
        },
        {
            path: "/pages/home/content",
            name:"content/category",
            component: () => import('../views/message/ContentDetail'),
        },
        {
            path: "/pages/checkin/checkin",
            name:"checkin/checkin",
            component: () => import('../views/user/CheckIn'),
        },
        {
            path: "/pages/home/vip",
            name:"home/vip",
            component: () => import('../views/user/Vip'),
        },
        {
            path: "/pages/home/message-detail",
            name:"home/message-detail",
            component: () => import('../views/message/NotifyDetail'),
        },
    ]
})

export default router;