import request from "../utils/request"

// export const initUserData = () => {
//     return request.get("index/initUserData")
// }

//用户登录
export const userLogin = (params) => {
    return request.post("membershipLogin", params)
}

//用户注册
export const userRegister = (params) => {
    return request.post("membershipRegister", params)
}
//{"status":200,"message":"Insert Successfully","result":[{"insertLastId":28}]}

//首页数据
export const getHomeData = () => {
    return request.get("homeScreen")
}

//用户详情
export const getUserDetail = () => {
    return request.get("memberDetail")
}

//app信息
export const getAppInfo = () => {
    return request.get("appInformation")
}

//获取客服信息
export const getCustomerService = () => {
    return request.get("contactCustomerService")
}

export const getBusinessHour = () => {
    return request.get("bussinessHour")
}

//用户财务收入
export const getFinanceIncome = () => {
    return request.get("financeIncome")
}

//用户抢单记录
export const getOrderHistory = (params) => {
    return request.get("orderHistory", params)
}

export const uploadFile = (params) => {
    return request.post("uploadFile", params)
}

//修改用户性别和头像
export const updateUserInfo = (params) => {
    return request.put("updateMemberInformation", params)
}

//修改登录密码
export const updateLoginPassword = (params) => {
    return request.put("updateLoginPassword", params)
}

//修改提现密码
export const updateWithdrawPassword = (params) => {
    return request.put("updateWithdrawPassword", params)
}

//获取文章详情
export const getAppArticle = (params) => {
    return request.get("appArticle", params)
}

//获取通知列表
export const getNotifications = (params) => {
    return request.get("notification", {params: params})
}

//获取通知详情
export const getNotifiDetail = (id) => {
    return request.get("notification/"+id)
}

//提现
export const withdrawal = (params) => {
    return request.post("withdrawal", params)
}

//用户提现记录
export const getWithdrawalHistory = (params) => {
    return request.get("withdrawalHistory", params)
}

//用户创造结算卡
export const setTlementCard = (params) => {
    return request.post("settlementCard", params)
}

//结算卡详情
export const getTlementCard = () => {
    return request.get("settlementCard")
}

//产品(三个产品)
export const getProducts = () => {
    return request.get("product")
}

//下普通单
export const normalOrder = (id) => {
    return request.post("normalOrder", {"productId": id})
}

//下连单
export const lianOrder = () => {
    return request.post("lianOrder")
}

//获取订单详情
export const getOrderDetail = (id) => {
    return request.get("orderDetail/"+id)
}

//编辑订单状态
export const modifyOrderStatus = (id) => {
    var params = {}
    params["orderId"] = id
    params["status"] = "completed"
    return request.put("orderStatus", params)
}

//用户签到
export const checkIn = (date) => {
    return request.post("checkin", {"checkin_date": date})
}

//获取当月签到
export const getMonthCheckIn = (month) => {
    return request.get("checkin", {params: {"month": month}})
}

//获取签到奖励
export const getCheckinReward = () => {
    return request.get("checkinReward")
}

//获取评论
export const getComments = () => {
    return request.get("comment")
}

//提交评论
export const commentOrder = (orderId, commentId) => {
    return request.put("commentOrder", {"orderId": orderId, "commentId": commentId})
}