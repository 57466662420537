import { ref, provide } from 'vue';

export default function useHtmlDialog(){
  const isHtmlDialogShow = ref(false);
  const htmlDialogType = ref(0)

  const showHtmlDialog = (isShow, type = 0) => {
    isHtmlDialogShow.value = isShow
    htmlDialogType.value = type
  }

  provide('isHtmlDialogShow', isHtmlDialogShow);
  provide('htmlDialogType', htmlDialogType);
  provide('showHtmlDialog', showHtmlDialog);

  return {
    isHtmlDialogShow,
    htmlDialogType,
    showHtmlDialog,
  }
}