import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import GlobalDialog from './views/public/GlobalDialog.vue';
import useLoading from './composables/useLoading';
import useHtmlDialog from './composables/useHtmlDialog';
export default {
  __name: 'App',
  setup(__props) {
    const {
      isLoadingShow,
      loadingMessage
    } = useLoading();
    const {
      isHtmlDialogShow,
      htmlDialogType
    } = useHtmlDialog();
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_RouterView), _createVNode(GlobalDialog, {
        isLoadingShow: _unref(isLoadingShow),
        loadingMessage: _unref(loadingMessage),
        isHtmlDialogShow: _unref(isHtmlDialogShow),
        htmlDialogType: _unref(htmlDialogType)
      }, null, 8, ["isLoadingShow", "loadingMessage", "isHtmlDialogShow", "htmlDialogType"])]);
    };
  }
};