import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { mapState } from 'vuex';
import { inject } from 'vue';
import { getAppArticle } from '@/api';
export default {
  props: {
    isLoadingShow: false,
    loadingMessage: "Loading...",
    isHtmlDialogShow: false,
    htmlDialogType: 0
  },
  setup() {
    const showHtmlDialog = inject('showHtmlDialog');
    return {
      showHtmlDialog
    };
  },
  data() {
    return {
      isShow: true,
      articleDetail: null,
      titles: ["", "Certificate", "About US", "FAQ", "Events", "", "Agreement Terms", "Terms & Conditions"]
    };
  },
  watch: {
    isHtmlDialogShow: {
      handler: function (to, from) {
        if (to) {
          this.getDetail();
        }
      },
      // 设置为深度监听
      deep: true
    }
  },
  methods: {
    getDetail() {
      this.articleDetail = "";
      var params = {};
      params["type"] = "vip";
      params["articalId"] = this.htmlDialogType;
      getAppArticle({
        params: params
      }).then(res => {
        var detail = res.result[0].articleDetail;
        this.articleDetail = this.processHtml(detail);
      }).catch(err => {});
    },
    processHtml(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // 获取所有 img 和 script 标签
      const tags = doc.querySelectorAll('img, script');
      tags.forEach(tag => {
        if (tag.src && !tag.src.startsWith('http')) {
          tag.src = this.getAbsoluteUrl(tag.src);
        }
        if (tag.srcset && !tag.srcset.includes('http')) {
          tag.srcset = this.getAbsoluteUrl(tag.srcset);
        }
        tag.src = tag.src.replaceAll(window.location.host, "shop.bela.ltd");
      });
      return doc.body.innerHTML;
    },
    getAbsoluteUrl(relativeUrl) {
      // 使用 window.location 来获取当前页面的 URL 并拼接相对路径
      return new URL(relativeUrl, window.location.href).href;
    }
  }
};