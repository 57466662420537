import { ref, provide } from 'vue';

export default function useLoading() {
  const isLoadingShow = ref(false);
  const loadingMessage = ref("Loading...")

  const showLoading = (message = null, autoCloseTime = 0) => {
    isLoadingShow.value = true;
    if(message != null){
        loadingMessage.value = message
        if(autoCloseTime == 0) autoCloseTime = 1500
    }else{
        loadingMessage.value = "Loading..."
        if(autoCloseTime == 0) autoCloseTime = 6000
    }
    if(autoCloseTime > 0){
        setTimeout(() => {
            isLoadingShow.value = false;
        }, autoCloseTime)
    }
  };

  const closeLoading = () => {
    isLoadingShow.value = false;
  };

  provide('isLoadingShow', isLoadingShow);
  provide('loadingMessage', loadingMessage);
  provide('showLoading', showLoading);
  provide('closeLoading', closeLoading);

  return {
    isLoadingShow,
    loadingMessage,
    showLoading,
    closeLoading,
  };
}