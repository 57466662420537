import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";

export default createStore({
    plugins: [createPersistedState()],
    state(){
        return{
            baseUrl: "https://shop.bela.ltd",
            moneySymbol: '$',
            isShowUserCenterPopup: true,
            userDetail: {
                memberAccount: null,
                memberProfile: null,
                memberLevelIcon: null,
                referralCode: null,
                creditScore: null,
                todayProfit: null,
                balance: null,
                totalAsset: null,
                todayOrder: 0,
                maximunOrder: 0,
            },
        } 
    },

    mutations: {
        updateUserDetail(state, userDetail){
            state.userDetail = userDetail
        },
        showUserCenterPopup(state, isShow){
            state.isShowUserCenterPopup = isShow
        },
    },

    //为异步操作准备的
    actions: {
        updateUserDetail({ commit }, userDetail) {
            commit('updateUserDetail', userDetail);
        },
        showUserCenterPopup({ commit }, isShow){
            commit('showUserCenterPopup', isShow);
        },
    }

    
})